import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_update_collection_info = _resolveComponent("update-collection-info")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_update_collection_info, {
        key: 0,
        estimationIdentifier: _ctx.estimationIdentifier,
        onGoToNextSection: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.routerRoute)))
      }, null, 8, ["estimationIdentifier"]))
    : _createCommentVNode("", true)
}