
import { Options, Vue } from 'vue-class-component';
import UpdateCollectionInfo from '../../components/updateCollectionInfo.vue';
import { FabricEstimationClient } from '@/services/Services';

@Options({
    components: {
        UpdateCollectionInfo
    }
})
export default class CollectionInfo extends Vue {

    estimationIdentifier: string = "";
    routerRoute: string = "";
    loaded: boolean = false;
    
    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        Promise.all([
            FabricEstimationClient.checkIfHasFarming(this.estimationIdentifier),
            FabricEstimationClient.checkIfHasWool(this.estimationIdentifier)
        ])
        .then(xs => {
            if(xs[0])
                this.routerRoute = '/fabric-calculator/farming/' + this.estimationIdentifier;
            else if(xs[1])
                this.routerRoute = '/fabric-calculator/wool/' + this.estimationIdentifier;
            else
                this.routerRoute = '/fabric-calculator/rawmaterials/' + this.estimationIdentifier;
            
            setTimeout(() => {
                this.loaded = true;
            }, 300);
        })
    }
}
